
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import FormListMixin from '@/components/form/mixins/FormListMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'

@Options({
  components: { Empty },
  directives: { maska },
})
export default class FormsSetting extends mixins(FormListMixin) {
  async created() {
    this.$meta.setMeta({ title: ['Form setting'] })
  }
}
