<template>
  <section class="q-pa-md">
    <div class="row no-wrap">
      <div>
        <h1 class="text-h4">{{ workspace.title }}</h1>
        <p class="text-subtitle2">Forms setting</p>
      </div>
    </div>

    <div class="container-smaller q-mt-lg">
      <q-list bordered separator class="rounded-borders bg-grey-1">
        <q-item clickable v-ripple v-for="form in forms" :key="form._id">
          <q-item-section class="text-subtitle2" @click="gotoDetailForm(form)">
            {{ form.title }}
          </q-item-section>
          <q-item-section side>
            <div class="q-gutter-sm">
              <q-btn size="sm" round icon="edit" @click="gotoDetailForm(form)" />
              <q-btn size="sm" round color="red" icon="delete" @click="onDelete(form)" />
            </div>
          </q-item-section>
        </q-item>
      </q-list>

      <Empty v-if="!forms.length" />

      <div class="q-mt-lg">
        <q-btn color="primary" icon="add" @click.prevent="gotoNewForm" label="New form" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import FormListMixin from '@/components/form/mixins/FormListMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'

@Options({
  components: { Empty },
  directives: { maska },
})
export default class FormsSetting extends mixins(FormListMixin) {
  async created() {
    this.$meta.setMeta({ title: ['Form setting'] })
  }
}
</script>
