
import { Options, mixins } from 'vue-class-component'
import { FormModel } from '@/components/form/form-model'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import FormMixin from './FormMixin.vue'

@Options({
  components: {},
})
export default class FormListMixin extends mixins(FormMixin) {
  modalFormFormVisible = false
  selected: FormModel | null = null

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get forms(): FormModel[] {
    return this.$store.getters.forms(this.workspaceId) || []
  }

  async onDelete(form: FormModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.removeForm(form.sertialize())
      })
  }

  onEdit(form: FormModel) {
    this.modalFormFormVisible = true
    this.selected = form
  }

  onCloseModal() {
    this.modalFormFormVisible = false
    this.selected = null
  }

  gotoDetailForm(form: FormModel) {
    this.$router.push({
      name: 'workspace_board.form_fields',
      params: {
        formId: form._id || '',
      },
    })
  }

  gotoNewForm() {
    this.$router.push({
      name: 'workspace_board.form_fields',
    })
  }

  async created() {
    await this.getForms()
  }
}
